<template>
  <div class="hero min-h-screen bg-base-200">
    <div class="text-center hero-content">
      <div class="max-w-md">
        <h1 class="mb-5">
          It seems like the link you have recevied is not valid.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {}

</script>

<style>

</style>
